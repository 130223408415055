import * as React from "react";
import Layout from "../components/Layout";
import PartnerCarousel from "../components/carousels/PartnerCarousel";
// import { motion, useAnimation } from 'framer-motion';
import { useInView } from "react-intersection-observer";
import ScrollAnimation from "react-animate-on-scroll";

//Images
import rsaFlag from "../images/rsa-flag.svg";
import bbbee from "../images/bbbee.svg";
import blueprint from "../images/Blueprint.png";
import mobileblueprint1 from "../images/mobile-bp-1.png";
import mobileblueprint2 from "../images/mobile-bp-2.png";
import mobileblueprint3 from "../images/mobile-bp-3.png";

import "animate.css/animate.min.css";

import countries from "../images/numbers/countries.svg";
import consultants from "../images/numbers/consultants.svg";
import continents from "../images/numbers/continents.svg";
import years from "../images/numbers/years.svg";

import squareElement2 from "../images/square-element2.svg";
import squareElement3a from "../images/square-element3a.svg";
import squareElement3b from "../images/square-element3b.svg";
import squareElement3c from "../images/square-element3b.svg";
import squareElement3d from "../images/red-square.svg";

//CSS
import "../styles/index.scss";
import "../styles/grids.scss";
import "../styles/particles.scss";
import "../styles/buttons.scss";
import "../styles/elements.scss";
import "../styles/animations.scss";
import TestimonialCarousel from "../components/carousels/TestimonialCarousel";
import ContactForm from "../components/ContactForm";
import homeFlipBox from "../components/flipboxes/homeFlipBox";
import bbbeee from "../components/bbbeee";
import { Link } from "gatsby";
import SEO from "../components/seo";
// import { img } from 'gatsby-plugin-image';

// markup
const HomePage = () => {
  const [ref, inView] = useInView();

  let interval = 1000;

  const countUp = () => {
    if (typeof window !== "undefined") {
      let valueDisplays = document.querySelectorAll(".num");

      valueDisplays.forEach((valueDisplay) => {
        let startValue: number = 0;
        let endValue: any = valueDisplay.getAttribute("data-val");
        let endValue2 = parseInt(endValue);
        let duration = Math.floor(interval / endValue2);
        let counter = setInterval(function () {
          startValue += 1;
          valueDisplay.textContent = startValue.toString() + "+";
          if (startValue == endValue2) {
            clearInterval(counter);
          }
        }, duration);
      });
    }
  };

  React.useEffect(() => {
    if (inView) {
      countUp();
    }
  }, [inView]);

  const [screenWidth, setScreenWidth] = React.useState(0);

  const updateScreenX = () => {
    if (typeof window != "undefined") {
      setScreenWidth(window.innerWidth);
    }
  };

  React.useEffect(() => {
    updateScreenX();
    if (typeof window != "undefined") {
      window.addEventListener("resize", updateScreenX);
    }
  }, []);

  const props = {
    seoTitle: "Home",
    seoDescription:
      "Strategix Technology Solutions is a leading provider of managed and professional IT services, enabling multicloud operations through leading engineering skills.",
  };

  return (
    <Layout>
      <SEO
        title={props.seoTitle}
        description={props.seoDescription}
        // keywords={[]}
        lang="en-gb"
      />
      <main>
        {/* Home Hero */}
        <section>
          <div className="hero" id="home">
            <div className="w-max">
              <div className="grid-column-3">
                <div
                  className="flex-col-c fade-intro landing-text"
                  style={{ minHeight: "88vh" }}
                >
                  <div className="home-text-mobile">
                    <h1>
                      Accelerate your business with Strategix Technology
                      Solutions
                    </h1>
                    <p>
                      {" "}
                      <strong
                      // style={{ color: 'var(--blue)', fontStyle: 'italic' }}
                      >
                        The best kept secret for modernising ICT infrastructure,
                        data, applications and security.
                      </strong>{" "}
                    </p>
                  </div>
                </div>
                <div className="business-simplified fade-intro">
                  <h1>
                    Business, <span>simplified.</span>
                  </h1>
                </div>
              </div>

              {/* Value Proposition */}
              <ScrollAnimation animateIn="animate__fadeInUp" duration={2}>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    maxWidth: "100vw",
                    display: "flex",
                    alignItems: "flex-end",
                    zIndex: "9",
                  }}
                  id="vp-home"
                >
                  Create business agility and respond seamlessly to today's
                  market demands by automating and streamlining IT services.
                </h2>
              </ScrollAnimation>
            </div>
          </div>
        </section>
        {/* Video Section */}
        <section className="half" style={{ zIndex: "9" }}>
          <div className="video-wrapper">
            <div className="w-max">
              <ScrollAnimation animateIn="animate__fadeIn" duration={2}>
                <h2
                  style={{ textAlign: "center", paddingBottom: "2rem" }}
                  className="fade-intro"
                >
                  IT Complexity Doesn't Have To Slow Your Business Down.
                </h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" duration={0.75}>
                <div className="grid-column-12">
                  <div className="home-vid">
                    <iframe
                      className="embed-resp"
                      width="560"
                      height="315"
                      srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/Ymj9nBns3iM/?autoplay=1><img src=https://img.youtube.com/vi/Ymj9nBns3iM/0.jpg alt='Strategix - VMware Virtualisation'><span>▶</span></a>`}
                      // src="https://www.youtube.com/embed/Ymj9nBns3iM"
                      title="YouTube Video"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div className="vid-description fade-intro">
                    <img alt="" src={rsaFlag} className="rsa-flag" />
                    <div
                      id="home-flag"
                      style={{
                        minHeight: "100%",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <p>
                        Strategix Technology Solutions unlocks greater IT and
                        business value by accessing leading engineering skills
                        and flexibility and providing managed services on our
                        local and international enterprise grade cloud.
                      </p>
                      <div>
                        <Link to="/about">
                          <button
                          // whileHover={{ scale: 1.05 }}
                          // whileTap={{ scale: 1.05 }}
                          // className="save-button"
                          // onClick={() => null}
                          >
                            About Us <span>&#x279C;</span>
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="red-square-outline">
                      <svg
                        width="200"
                        height="200"
                        viewBox="0 0 200 200"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <g
                          id="Rectangle_676"
                          stroke="#B93835"
                          stroke-width="5"
                          fill="none"
                          transform="matrix(0.707107 0.707107 -0.707107 0.707107 96.361 0)"
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="15"
                            width="136.275"
                            height="136.275"
                            stroke="none"
                          />
                          <rect
                            x="2.5"
                            y="2.5"
                            rx="12.5"
                            width="131.275"
                            height="131.275"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </section>
        {/* <div className="square-element1">
          <img alt="" src={squareElement1} />
        </div> */}
        {/* Our Offerings */}
        <section>
          <div className="offerings-wrapper">
            <div className="w-max">
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                {" "}
                <h1 className="fade-in-up" style={{ textAlign: "center" }}>
                  Our Offerings
                </h1>
                <p className="fade-in-up">
                  Our portfolio of solutions and products to accelerate digital
                  transformation.
                </p>
              </ScrollAnimation>
              {homeFlipBox()}
            </div>
          </div>
        </section>
        <div
          className="square-element1"
          style={{ transform: "rotate(180deg)" }}
        >
          <img
            alt=""
            src={squareElement3c}
            style={{ transform: "scale(0.5)" }}
          />
        </div>
        <div
          className="square-element1b"
          style={{ transform: "rotate(180deg)" }}
        >
          <img
            alt=""
            src={squareElement3d}
            style={{ transform: "scale(0.5)" }}
          />
        </div>
        {/* Services */}
        <section>
          <div className="services-wrapper" style={{ zIndex: "9" }}>
            <div className="w-max">
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                <h1 className="fade-in-up">The STS Blueprint</h1>
                <p className="fade-in-up">
                  A guided approach to digital transformation and managing
                  business systems.
                </p>
              </ScrollAnimation>
              {/* <Blueprint /> */}
              {screenWidth >= 900 ? (
                <>
                  <img
                    alt="Strategix Technology Solutions Blueprint"
                    src={blueprint}
                    className="sts-blueprint"
                  />
                </>
              ) : (
                <>
                  <img
                    alt="Strategix Technology Solutions - Private and Hybrid Cloud"
                    src={mobileblueprint1}
                    className="sts-blueprint"
                  />
                  <img
                    alt="Strategix Technology Solutions - Consume and Integrate"
                    src={mobileblueprint2}
                    className="sts-blueprint"
                  />
                  <img
                    alt="Strategix Technology Solutions - Digital Enterprise"
                    src={mobileblueprint3}
                    className="sts-blueprint"
                  />
                </>
              )}
            </div>
          </div>
        </section>
        {/* Partners */}
        <section style={{ minHeight: "50vh" }}>
          <PartnerCarousel />
        </section>
        {/* STS By the numbers */}
        <section className="half">
          <div className="numbers-wrapper half">
            <div className="w-max">
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                <h1
                  style={{ textAlign: "center", paddingBottom: "1rem" }}
                  className="fade-in-up"
                  id="numbers-tagline"
                >
                  Strategix Technology Solutions by the Numbers
                </h1>
              </ScrollAnimation>
              <div className="grid-column-13">
                <div className="span-1 numbers flex-col-c">
                  <ScrollAnimation animateIn="animate__fadeIn" duration={1}>
                    <img
                      alt=""
                      src={consultants}
                      className="image-fit home-icons"
                    />
                  </ScrollAnimation>
                </div>
                <div className="span-2 numbers flex-col-c">
                  <ScrollAnimation animateIn="animate__fadeIn" duration={0.75}>
                    <h1
                      ref={ref}
                      style={{
                        color: "var(--red)",
                        textAlign: "left",
                        fontSize: "4rem",
                        width: "100%",
                        marginTop: "0",
                      }}
                      className="num"
                      data-val="30"
                    >
                      00
                    </h1>
                    <p style={{ fontWeight: "600" }}>
                      Highly qualified consultants.
                    </p>
                  </ScrollAnimation>
                </div>
                <div className="span-1 numbers flex-col-c">
                  <ScrollAnimation animateIn="animate__fadeIn" duration={1}>
                    <img
                      alt=""
                      src={countries}
                      className="image-fit home-icons"
                    />
                  </ScrollAnimation>
                </div>
                <div className="span-2 numbers flex-col-c">
                  {" "}
                  <ScrollAnimation animateIn="animate__fadeIn" duration={0.75}>
                    <h1
                      style={{
                        color: "var(--red)",
                        textAlign: "left",
                        fontSize: "4rem",
                        width: "100%",
                        marginTop: "0",
                      }}
                      className="num"
                      data-val="20"
                    >
                      00
                    </h1>
                    <p style={{ fontWeight: "600" }}>
                      Countries with successful delivery.
                    </p>
                  </ScrollAnimation>
                </div>
                <div className="span-1 numbers flex-col-c" id="africa">
                  <ScrollAnimation animateIn="animate__fadeIn" duration={1}>
                    <img
                      alt=""
                      src={continents}
                      className="image-fit home-icons"
                    />
                  </ScrollAnimation>
                </div>
                <div className="span-2 numbers flex-col-c">
                  <ScrollAnimation animateIn="animate__fadeIn" duration={0.75}>
                    <h1
                      style={{
                        color: "var(--red)",
                        textAlign: "left",
                        fontSize: "4rem",
                        width: "100%",
                        marginTop: "0",
                      }}
                      className="num"
                      data-val="3"
                    >
                      00
                    </h1>
                    <p style={{ fontWeight: "600" }}>Continents reached.</p>
                  </ScrollAnimation>
                </div>
                <div className="span-1 numbers flex-col-c">
                  <ScrollAnimation animateIn="animate__fadeIn" duration={1}>
                    <img alt="" src={years} className="image-fit home-icons" />
                  </ScrollAnimation>
                </div>
                <div className="span-2 numbers flex-col-c">
                  <ScrollAnimation animateIn="animate__fadeIn" duration={0.75}>
                    <h1
                      style={{
                        color: "var(--red)",
                        textAlign: "left",
                        fontSize: "4rem",
                        width: "100%",
                        marginTop: "0",
                      }}
                      className="num"
                      data-val="17"
                    >
                      00
                    </h1>
                    <p style={{ fontWeight: "600" }}>Years in business.</p>
                  </ScrollAnimation>
                </div>
                <div className="span-8" style={{ paddingInline: "0" }}>
                  <div className="numbers-text">
                    <div
                      className="by-the-num-bee"
                      style={{
                        display: "flex",
                        backgroundColor: "var(--grey)",
                        borderRadius: "20px",
                        paddingInline: "2.5rem",
                        paddingBlock: "1rem",
                        alignItems: "center",
                      }}
                    >
                      <div className="sixty-width pad-text">
                        <h2>
                          A proven global track record of business success, 17+
                          years in the making.{" "}
                        </h2>
                      </div>
                      <div className="forty-width">
                        <img
                          alt=""
                          src={bbbee}
                          className="image-fit"
                          id="bbbee2"
                        />
                        <div className="image-fit" id="bbbee">
                          {bbbeee()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="square-element2">
          <img alt="" src={squareElement2} />
        </div>
        {/* Clients */}
        <section className="half" style={{ zIndex: "9" }}>
          <div className="clients-wrapper">
            <TestimonialCarousel />
          </div>
        </section>{" "}
        <div className="square-element3">
          <img alt="" src={squareElement3b} />
          <img alt="" src={squareElement3a} />
        </div>
      </main>

      {/* Contact */}
      <section className="half">
        <ContactForm />
      </section>
    </Layout>
  );
};

export default HomePage;
