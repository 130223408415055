import React from "react";

import "../../styles/card.scss";
//Icons
import { IoIosArrowDown } from "react-icons/io";
import { BiSearchAlt } from "react-icons/bi";
import { FaRegCircle } from "react-icons/fa";
import { FaCircle } from "react-icons/fa";

// import { motion, useAnimation } from 'framer-motion';
import { useInView } from "react-intersection-observer";
import ScrollAnimation from "react-animate-on-scroll";

import Card from "../Card";

export default function homeFlipBox() {
  return (
    <div className="outer-container">
      <div className="row">
        {/******** Desktop Cards *******/}
        <div className="desktop-card2">
          <ScrollAnimation animateIn="animate__fadeInUp" duration={1} delay={0}>
            <div className="inner-container-3">
              <Card
                heading="Professional Services"
                href="/professional-services"
                info="Unlock business value in your invested software."
              />
            </div>
          </ScrollAnimation>
        </div>
        <div className="desktop-card2">
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            duration={1}
            delay={100}
          >
            <div className="inner-container-3">
              <Card
                heading="Managed Services"
                href="/managed-services"
                info="Build an IT ecosystem that supports your business goals."
              />
            </div>
          </ScrollAnimation>
        </div>
        <div className="desktop-card2">
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            duration={1}
            delay={200}
          >
            <div className="inner-container-3">
              <Card
                heading="Cloud Services"
                href="/cloud-services"
                info="Build, deliver and manage effective multi-cloud operations."
              />
            </div>
          </ScrollAnimation>
        </div>

        <div className="desktop-card2">
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            duration={1}
            delay={300}
          >
            <div className="inner-container-3">
              <Card
                heading="Product"
                href="/contact"
                info="Contact us to find out about our wide selection of trusted and secure software."
              />
            </div>
          </ScrollAnimation>
        </div>
        {/******** Mobile Cards *******/}
      </div>
    </div>
  );
}
