import React, { useState } from "react";
import Slider from "react-slick";

// import { img } from 'gatsby-plugin-image';

import "../../styles/clientTestimonials.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import clientImg1 from "../../images/partners/z.png";
import clientImg2 from "../../images/partners/first-dist.png";
import clientImg3 from "../../images/partners/first-tech.png";
import clientImg4 from "../../images/partners/aa.png";
import clientImg5 from "../../images/partners/cbh.png";
import clientImg6 from "../../images/stefstocks.png";
import clientImg7 from "../../images/metsi.png";
import clientImg8 from "../../images/aovirtual.png";

import ScrollAnimation from "react-animate-on-scroll";
// import { StaticImage } from 'gatsby-plugin-image';

export default function TestimonialCarousel() {
  //   render() {
  const [imageIndex, setImageIndex] = useState(0);
  const [fade, setFade] = useState("fadeIn");

  const client4 = {
    image: clientImg1,
    client: "Zensar Technologies",
    clientPosition: "Ross Botha | Strategic Business Alliance Head",
    alt: "",
    testimonial:
      "Strategix has performed above and beyond, always been on call 24x7 with great response times no matter what time or day it is and have always delivered beyond our expectations.",
  };
  const client5 = {
    image: clientImg2,
    client: "First Distribution",
    clientPosition:
      "Bertus van Heerden | Pre-sales and Professional Services Manager",
    alt: "",
    testimonial:
      "Strategix has been delivering VMware solution sets for the past year. I recommend their Professional Services as they always deliver.",
  };
  const client7 = {
    image: clientImg3,
    client: "First Technology Group",
    clientPosition: "Gerhard Olivier | Solution Consulting Manager",
    alt: "",
    testimonial:
      "Strategix has always delivered professional consultation and Solution Architecture and I firmly believe in their service delivery, pre-sales as well as post sales services.",
  };
  const client1 = {
    image: clientImg4,
    client: "Automobile Association of South Africa",
    clientPosition: "Willem Groenewald | Chief Executive Officer",
    alt: "",
    testimonial:
      "Strategix is a key partner in our end-to-end digital transformation strategy to achieve our vision for 2030!",
  };
  const client2 = {
    image: clientImg5,
    client: "Country Bird Holdings",
    clientPosition: "Nick Robinson | Chief Information Officer",
    alt: "",
    testimonial:
      "We utilise the Strategix VMware Validate Cloud Platform for our all our non-Microsoft workload needs. They have been a key partner to help define our IT roadmap to modernise into various cloud platforms.",
  };
  const client6 = {
    image: clientImg6,
    client: "Stefanutti Stocks",
    clientPosition: "Kevin Wilson | General Manager IT Services",
    alt: "",
    testimonial:
      "We have been working with Strategix for many years and see them as a trusted advisor for many of our key IT requirements. The team is always willing to help brainstorm ideas to improve our business models which has helped to reduce IT spend.",
  };
  const client3 = {
    image: clientImg7,
    client: "Metsi Technologies",
    clientPosition: "Rachael Babcock | US Chief Executive Officer",
    alt: "",
    testimonial:
      "Metsi US values our engineering partnership with Strategix and leverages Strategix’s resources as an extension of the Metsi US engineering team. We value Strategix’s resourcefulness, professionalism, and integrity in their engineering delivery. Their VMware resources are highly skilled and understand the VMware stack exceptionally well, and I’ve been personally impressed by their attention to detail.",
  };
  const client8 = {
    image: clientImg8,
    client: "AO Virtual",
    clientPosition: "Rahul Mehta",
    alt: "",
    testimonial:
      "We have been working with Strategix for many years and see them as a trusted advisor for many of our key IT requirements. The team is always willing to help brainstorm ideas to improve our business models which has helped to reduce IT spend.",
  };
  const images = [
    client1,
    client2,
    client3,
    client4,
    client5,
    client6,
    client7,
    // client8,
  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    centerMode: true,
    arrows: true,
    dots: true,
    beforeChange: (current: number, next: number) => {
      setFade("fadeOut");
      if (typeof window !== "undefined") {
        window.setTimeout(() => {
          setImageIndex(next);
          window.setTimeout(() => {
            setFade("fadeIn");
          }, 250);
        }, 250);
      }
    },
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div
      className="carousel-wrapper"
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div className="w-max-nf">
        <ScrollAnimation animateIn="animate__fadeInUp" duration={0.7}>
          <h1 style={{ marginTop: "0", zIndex: "9" }}>
            What Our Clients Have To Say
          </h1>
        </ScrollAnimation>
        <br />
        <Slider {...settings}>
          {images.map((img, idx) => (
            <>
              <div
                className={idx === imageIndex ? "slide activeSlide" : "slide"}
                style={{
                  minHeight: "25vh",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={img.image}
                  alt={img.client}
                  className="carousel-logo"
                  placeholder="blurred"
                  style={{
                    borderRadius: "50%",
                    transform: "scale(1)",
                  }}
                />
              </div>
              <br />
            </>
          ))}
        </Slider>
        <br />
        <div className="flex-col-c">
          <p
            id="testimonial-text"
            style={{
              paddingInline: "7.5rem",
              paddingTop: "2rem",
              // fontSize: 'large',
              fontStyle: "italic",
              fontWeight: "500",
              maxWidth: "60%",
            }}
            className={fade}
          >
            "{images[imageIndex].testimonial}"
          </p>
          <p
            id="testimonial-text"
            style={{
              paddingInline: "7.5rem",
              // paddingTop: '2rem',
              fontSize: "small",
              margin: "0",
              fontStyle: "italic",
              fontWeight: "500",
              maxWidth: "60%",
            }}
            className={fade}
          >
            {images[imageIndex].clientPosition}
          </p>
          <h3
            className={fade}
            style={{ color: "var(--red)", textTransform: "capitalize" }}
          >
            {images[imageIndex].client}
          </h3>

          <br />
        </div>

        <br />
        {/* <button>
        MORE FROM OUR PARTNERS <span>&#x279C;</span>
      </button> */}
      </div>
    </div>
  );
}
// }
